import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HashLink } from "react-router-hash-link";
import React, { useState, useEffect, useRef } from "react";
import AudioPlayer from "../../components/audioPlayer/audioPlayer"; // Adjust the path as necessary

export default function NavigationBar() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [showBar, setShowBar] = useState(true);
  const lastScrollY = useRef(0);

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY.current && window.scrollY > 100) {
          setShowBar(false);
          onClose(); // Close mobile menu when navBar hides
        } else {
          setShowBar(true);
        }
        lastScrollY.current = window.scrollY;
      }
    };

    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [onClose]);

  return (
    <Box position="fixed" top="0" left="0" right="0" zIndex="banner">
      <Flex
        bg="rgba(255, 255, 255, 0.3)"
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("blackAlpha.200", "gray.900")}
        align={"center"}
        width="full" // Ensures it spans the full width of the viewport
        transform={showBar ? "translateY(0)" : "translateY(-100%)"}
        transition="transform 0.2s ease-in-out"
      >
        <Flex flex={{ base: 1 }} justify={{ base: "auto", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            fontSize={"5xl"}
            color={useColorModeValue("darkBlue.1000", "white")}
          >
            M y E
          </Text>
        </Flex>
        <Flex>
          <AudioPlayer></AudioPlayer>
        </Flex>
        <Flex
          flex={{ base: "right", md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>

        <Flex display={{ base: "none", md: "flex" }} ml={10}>
          <DesktopNav />
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav onClose={onClose} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("blue.1000", "white");
  const linkHoverColor = useColorModeValue("gray.400", "gray.400");
  const popoverContentBgColor = useColorModeValue("black", "gray.1000");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                fontSize={"lg"}
                fontWeight={600}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                as={HashLink}
                to={navItem.href ?? "#"}
                smooth
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      as={HashLink}
      to={href ?? "#"}
      smooth
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

interface MobileNavProps {
  onClose: () => void;
}

const MobileNav = ({ onClose }: MobileNavProps) => {
  return (
    <Stack
      bg={useColorModeValue("rgba(255, 255, 255, 0.3)", "gray.800")}
      p={4}
      display={{ md: "none" }}
      zIndex="banner" // Ensure the zIndex is set high
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );
};

interface MobileNavItemProps extends NavItem {
  onClose: () => void;
}

const MobileNavItem = ({
  label,
  children,
  href,
  onClose,
}: MobileNavItemProps) => {
  const { isOpen, onToggle } = useDisclosure();

  const handleItemClick = () => {
    if (children) {
      onToggle();
    } else {
      onClose();
    }
  };

  return (
    <Stack spacing={4} onClick={handleItemClick}>
      <Flex
        py={2}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        as={HashLink}
        smooth
        to={href ?? "#"}
      >
        <Text
          fontWeight={400}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                key={child.label}
                py={2}
                as={HashLink}
                smooth
                to={href ?? "#"}
                onClick={onClose}
              >
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Nosotros",
    href: "#about-us",
  },
  {
    label: "Detalles del evento",
    href: "#event-details",
  },
  {
    label: "Mesa de regalo",
    href: "#gift-table",
  },
  {
    label: "Confirmación",
    href: "#rsvp",
  },
  {
    label: "Fotos",
    href: "#picture-gallery",
  },
];
